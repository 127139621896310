import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import http from '@/global/http'

export default function useUserClientList() {
  const userClientList = ref([])

  // Table products
  const tableColumns = ref([
    {
      label: 'Id',
      key: 'id',
      tdClass: 'minw-80 sticky-column-left',
      thClass: 'sticky-column-left',
    },
    {
      label: 'Họ và tên',
      key: 'fullname',
    },
    {
      label: 'Chưa xác định',
      key: 'new',
    },
    {
      label: 'Lạnh',
      key: 'cold',
    },
    {
      label: 'Nguội',
      key: 'cool',
    },
    {
      label: 'Ấm',
      key: 'warm',
    },
    {
      label: 'Nóng',
      key: 'hot',
    },
    {
      label: 'Thân',
      key: 'familiar',
    },
    {
      label: 'Tổng số client',
      key: 'total_clients',
    },
  ])
  const perPage = ref(50)
  const perPageOptions = [10, 25, 50, 100]
  const currentPage = ref(1)
  const totalProducts = ref(0)

  const buildQuery = customFilter => http.buildQuery(customFilter || ({
    // page: currentPage.value - 1,
    // perpage: perPage.value,
    // limit: perPage.value,
  })) || ''

  const fetchReportUserClient = customFilter => {
    console.log('go there')
    const queryString = buildQuery(customFilter)
    return http.handle(store.dispatch('report/fetchReportUserClient', queryString), res => {
      console.log('res', res.data)
      userClientList.value = res.data || []
      totalProducts.value = res.total
    })
  }

  watch([currentPage, perPage], () => {
    fetchReportUserClient()
  })

  return {
    totalProducts,
    userClientList,
    tableColumns,
    perPage,
    currentPage,
    perPageOptions,
    buildQuery,
    // api
    fetchReportUserClient,
  }
}
